import React, { useEffect } from 'react'
import { Box, Image } from '@chakra-ui/react'
import GoogleMapReact from 'google-map-react'

const SupplierMap = ({ address }) => {
  const { address: add, city, state, zip_code } = address;
  const addr = add && city && state && zip_code
    ? `${add.replace(" ", "+")},+${city},+${state}+${zip_code}`
    : '';


  return (
    <Image
      src={
        `https://maps.googleapis.com/maps/api/staticmap?zoom=17&size=1000x1000&maptype=roadmap&markers=color:0xFF4545%7Clabel:S%7C${addr}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`
      }
      objectFit="cover"
      overflow="hidden"
    />
  )
}

export default SupplierMap;
