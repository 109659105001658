import {
  Box,
  Card,
  CardHeader,
  CardBody,
  Text,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverHeader,
} from '@chakra-ui/react'
import SupplierMap from './SupplierMap';

const ItemSupplierCard = ({ itemData, mockItem }) => {
  return (
    <Card
      variant="outline"
      h="100%"
      maxH="325px"
    >
      <CardHeader p={2}>
        <Popover trigger="hover" placement="right-start">
          <PopoverTrigger>
            <Box
              cursor="pointer"
              display="inline-flex"
              borderBottom="1px solid"
              borderBottomColor="health.500"
              _hover={{
                borderBottomColor: "health.900"
              }}
            >
              <Text fontSize="lg" fontWeight="bold">{itemData.supplier}</Text>
            </Box>
          </PopoverTrigger>
          <PopoverContent>
            <PopoverHeader>
              <Text>{itemData.supplier}</Text>
            </PopoverHeader>
            <PopoverArrow />
          </PopoverContent>
        </Popover>
      </CardHeader>
      <CardBody p={0} overflow="hidden" borderBottomRadius="inherit">
        <SupplierMap
          address={mockItem.supplier.address}
        />
      </CardBody>
    </Card>
  )
}

export default ItemSupplierCard;
