import {
  Box,
  Flex,
  Text,
} from '@chakra-ui/react'

const Classification = ({ classification }) => {
  return (
    <Box>
      <Flex direction="row" align="center">
        <Text w="100px" fontSize="sm" color="gray.400">SPECIALTY</Text>
        <Text>{classification.medical_specialty}</Text>
      </Flex>
    </Box>
  )
}

export default Classification;
