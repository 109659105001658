import { useState, useEffect } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalContent,
  Flex,
  Text,
  Avatar,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  IconButton
} from '@chakra-ui/react'

import PersonInfoAndContact from './PersonInfoAndContact'

import Sachin from '../../assets/sachin.jpeg'
import Nish from '../../assets/nish.jpeg'

const InfoModal = ({ isOpen, onClose }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent minW="700px" maxH="600px" overflow="hidden">
        <ModalHeader mr={5}>
          <Text>Introducing our AI-driven healthcare supply similarity tool!</Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody overflow="auto">
          <Card mb={2} border="1px solid" borderColor="health.900">
            <CardHeader px={2} py={1}>
              <Text fontSize="lg" fontWeight="bold" color="health.500">OUR GOAL</Text>
            </CardHeader>
            <CardBody px={2} pt={0} pb={2}>
              <Text fontWeight="semibold">
                Our mission is to create a single source of truth for healthcare networks' supply chains.
              </Text>
            </CardBody>
          </Card>
          <Card mb={2}>
            <CardHeader px={2} py={1}>
              <Text fontSize="lg" fontWeight="bold" color="health.500">ABOUT THIS TOOL</Text>
            </CardHeader>
            <CardBody px={2} pt={0} pb={2}>
              <Text>
                We built this tool to make it easier for materials managers and runners alike to understand their equipment
                and deal with materials substitutions more efficiently. We know that last-minute substitutions are a
                recurring problem that slows down operating times, complicates scheduling, and messes up procure-to-pay cycles,
                so we built this as a way to ease substitution decision-making.<br /><br />
                
                <Text fontSize="sm" fontStyle="italic">We aggregated supplier and supply data from a bunch
                of public databases; please reach out to us if you would like the raw data!</Text>
              </Text>
            </CardBody>
          </Card>
          <Card mb="2">
            <CardHeader px={2} py={1}>
              <Text fontSize="lg" fontWeight="bold" color="health.500">ABOUT US</Text>
            </CardHeader>
            <CardBody px={2} pt={0} pb={3}>
              <Flex direction="column" gap={5}>
                <Text>We are two Duke graduates who are passionate about modernizing the healthcare supply chain. Thank you for using our tool!</Text>
                <PersonInfoAndContact
                  imgSrc={Sachin}
                  blurb={
                    ["Hey, I'm Sachin! I've built secure, HIPAA-compliant software and data pipelines at Google and worked on scalable", 
                    "supply chain tools at Cisco. My mission is to make supply chain data truthful, useful, and accessible."].join(" ")
                  }
                  linkedIn={"https://www.linkedin.com/in/sachinjaishankar"}
                  twitter={"https://www.twitter.com/sachinjshankar"}
                  email={"sachin@unstatiq.com"}
                  mb={3}
                />
                <PersonInfoAndContact
                  imgSrc={Nish}
                  blurb={
                    ["Hi I'm Nish! I've worked heavily with AI systems at Apple and Microsoft, and am passionate about building sustainable", 
                    "AI solutions to help deal with healthcare supply chain behemoths."].join(" ")
                  }
                  linkedIn={"https://www.linkedin.com/in/nish-singaraju-9472ba175/"}
                  twitter={"https://www.twitter.com/NishSingaraju"}
                  email={"nish@unstatiq.com"}
                />
              </Flex>
            </CardBody>
          </Card>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default InfoModal;
