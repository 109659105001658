import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Box,
  Flex,
  Stat,
  StatNumber,
  StatLabel,
  StatHelpText,
  Text,
  Tag
} from '@chakra-ui/react'

const ItemAuthorizationCard = ({ itemData, mockItem }) => {
  return (
    <Card h="100%" variant="outline" overflow="hidden">
      <CardHeader p={2} borderBottom="1px solid" borderBottomColor="gray.300">
        <Flex direction="row" align="center" justify="space-between">
          <Flex direction="column">
            <Text fontSize="xs" color="gray.500">APPROVAL</Text>
            <Text fontSize="md" fontWeight="bold">{mockItem.pmas ? "Pre-Market Approval" : mockItem.k_510s ? "510(k)" : "None"}</Text>
          </Flex>
          <Flex direction="column" align="end">
            <Text fontSize="sm" fontWeight="bold"><span style={{ fontSize: "0.75rem", color: "#718096", fontWeight: "500" }}>SUBMITTED:</span> {mockItem.pmas.date_received}</Text>
            <Text fontSize="sm" fontWeight="bold"><span style={{ fontSize: "0.75rem", color: "#718096", fontWeight: "500" }}>DECIDED:</span> {mockItem.pmas.decision_date}</Text>
          </Flex>
        </Flex>
      </CardHeader>
      <CardBody px={2} py={1} overflowY="auto">
        <Flex direction="row" mb={3} gap={2}>
          <Flex direction="column">
            <Text fontSize="xs" fontWeight="light" minW="115px" maxW="115px">GENERIC NAME</Text>
            <Text fontWeight="bold" fontSize="sm">{mockItem.pmas.generic_name}</Text>
          </Flex>
          <Flex direction="column">
            <Text fontSize="xs" fontWeight="light" minW="150px" maxW="150px">TRADE NAME</Text>
            <Text fontWeight="bold" fontSize="sm">{mockItem.pmas.trade_name}</Text>
          </Flex>
        </Flex>
        <Flex direction="row" mb={3} gap={2}>
          <Flex direction="column">
            <Text fontSize="xs" fontWeight="light" minW="115px" maxW="115px">SUPPLEMENT TYPE</Text>
            <Text fontWeight="bold" fontSize="sm">{mockItem.pmas.supplement_type}</Text>
          </Flex>
          <Flex direction="column">
            <Text fontSize="xs" fontWeight="light" minW="150px" maxW="150px">SUPPLEMENT REASON</Text>
            <Text fontWeight="bold" fontSize="sm">{mockItem.pmas.supplement_reason}</Text>
          </Flex>
        </Flex>
        <Text fontWeight="bold" fontSize="sm">Approval Order Statement</Text>
        <Text fontSize="xs" fontWeight="light">{mockItem.pmas.ao_statement}</Text>
      </CardBody>
      <CardFooter p={1}>
        <Box />
      </CardFooter>
    </Card>
  );
}

export default ItemAuthorizationCard;
