import {
  Card,
  CardBody,
  CardHeader,
  Tag,
  Stack,
  Flex,
  Image,
  Text,
  Tooltip
} from '@chakra-ui/react'

const ItemInfoCard = ({ itemData }) => {
  return (
    <Card
      h="100%"
      direction={{ base: 'column', sm: 'row'}}
      variant="outline"
      overflow="hidden"
    >
      <Image
        objectFit='cover'
        maxW={{ base: '100%', sm: '200px' }}
        src={itemData.image}
        fallbackSrc='https://images.unsplash.com/photo-1667489022797-ab608913feeb?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHw5fHx8ZW58MHx8fHw%3D&auto=format&fit=crop&w=800&q=60'
        alt='Caffe Latte'
      />
      <Stack spacing={0} py={1}>
        <CardHeader p={0} mx={3} my={1} borderBottom="1px solid" borderColor="gray.300">
          <Flex direction="column">
            <Flex direction="row" justify="space-between">
              <Text fontSize="xs" color="gray.400">{itemData.productCode}</Text>
              <Flex direction="row" gap={2} align="center">
                <Tooltip label="Category" placement="top">
                  <Tag size="sm">
                    {itemData.category}
                  </Tag>
                </Tooltip>
                <Tooltip label="Sub-category" placement="top">
                  <Tag size="sm">
                    {itemData.subCategory}
                  </Tag>
                </Tooltip>
                <Tooltip label="Use Case" placement="top">
                  <Tag size="sm">
                    {itemData.useCase}
                  </Tag>
                </Tooltip> 
              </Flex>
            </Flex>
            <Text fontSize="lg" fontWeight="semibold">{itemData.name}</Text>
          </Flex>
        </CardHeader>
        <CardBody p={0} mx={3}>
          <Text fontSize="sm" fontWeight="light">{itemData.description}</Text>
        </CardBody>
      </Stack>
    </Card>
  )
}

export default ItemInfoCard;
