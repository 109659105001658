import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { useQuery, useApolloClient } from '@apollo/client'
import { SEARCH } from '../graphql/queries'

import {
  Box,
} from '@chakra-ui/react'

import DataTable from './Common/DataTable';
import StaticSearch from './Common/StaticSearch';
import InfoModal from './Common/InfoModal'

const ItemTable = () => {
  const client = useApolloClient();

  const [searchQuery, setSearchQuery] = useState('');
  const [sheetHeaders, setSheetHeaders] = useState([]);

  const [sheetData, setSheetData] = useState([]);
  const [searchedData, setSearchedData] = useState([]);
  
  const [showSearchData, setShowSearchData] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);



  const { loading: searchLoading, error: searchError, data: searchData } = useQuery(SEARCH, {
    variables: {
      query: searchQuery
    }
  });
  // const { loading: fetchLoading, error: fetchError, data: fetchData } = useQuery(GET_PRODUCTS);


  const navigate = useNavigate();

  function convertDataToRender(products) {
    // Extract Object keys
    const keysArray = Object.keys(products[0]);
  
    // Create an array of objects for the first result
    const keysArrayResult = keysArray.map(key => ({ name: key, type: "string" }));
  
    // Create an array of values for the second result
    const valuesArrayResult = products.map(product => keysArray.map(key => product[key]));
  
    return [keysArrayResult, valuesArrayResult];
  }

  // useEffect(() => {
  //   if (fetchData && fetchData.products.length > 0) {
  //     const [productsHeaders, productsData] = convertDataToRender(fetchData.products);
  //     setSheetHeaders(productsHeaders);
  //     setSheetData(productsData);
  //   }
  // }, [fetchLoading])

  useEffect(() => {
    if (searchQuery === '') {
      setShowSearchData(false);
    }
  }, [searchQuery])

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSearchData();
    }
  }

  const handleSearchData = async () => {
    try {
      if (searchQuery.length >= 3) {
        const { data } = await client.query({
          query: SEARCH,
          variables: { query: searchQuery },
        });

        const [searchedHeaders, searchedData] = convertDataToRender(data.search)
        setSearchedData(searchedData);
        setShowSearchData(true);
      }
    } catch (err) {
      console.warn(err);
    }
  }

  
  return (
    <Box h="100%" px={"150px"}>
      {/* <Flex w="100%" minW="350px" direction="row" align="center" gap={2}> */}
        <StaticSearch
          value={searchQuery}
          placeholder={"Search items..."}
          onChange={(e) => setSearchQuery(e.target.value)}
          onKeyDown={handleKeyDown}
          onSubmit={handleSearchData}
        />
      {/* </Flex> */}
      {showSearchData ? (
        <DataTable
          my={3}
          title={"Healthcare Item Master"}
          description={"Search for or select an item to see its catalog and items most similar to it."}
          columns={sheetHeaders}
          excludeColumns={["__typename", "id"]}
          data={searchedData}
          onRowClick={(id) => navigate(`/item/${id}`)}
          isLoading={searchLoading}
        />
      ) : (
        <DataTable
          my={3}
          title={"Healthcare Item Master"}
          description={"Search for or select an item to see its catalog and items most similar to it."}
          columns={sheetHeaders}
          excludeColumns={["__typename", "id"]}
          data={sheetData}
          onRowClick={(id) => navigate(`/item/${id}`)}
          // isLoading={fetchLoading}
        />
      )}
    </Box>
  )
}

export default ItemTable;
