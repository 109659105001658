import {
  Box,
  Flex,
  Text,
  InputGroup,
  InputRightElement,
  IconButton
} from '@chakra-ui/react'
import { FaSearch } from 'react-icons/fa'

import StaticInput from './StaticInput'

const StaticSearch = ({ value, placeholder, onChange, onSubmit, ...props}) => {
  return (
    <InputGroup {...props}>
      <StaticInput
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        onSubmit={onSubmit}
        onKeyDown={(e) => { if (e.key === 'Enter') onSubmit();}}
      />
      <InputRightElement>
        <IconButton
          icon={<FaSearch />}
          size="sm"
          variant="ghost"
          color="health.500"
          onClick={onSubmit}
          _hover={{
            bg: "none",
            // boxShadow: "sm",
            color: "health.900"
          }}
          _active={{
            bg: "none",
            transform: "translateY(2px)"
          }}
        />
      </InputRightElement>
    </InputGroup>
  )
}

export default StaticSearch;
