import {
  Box,
  Flex,
  IconButton,
  Text,
  useColorMode
} from '@chakra-ui/react'
import { FaInfoCircle } from 'react-icons/fa';
import { MdOutlineDarkMode, MdDarkMode } from 'react-icons/md';

const Footer = ({ setShowInfoModal }) => {
  const { colorMode, toggleColorMode } = useColorMode();

  return (
    <Flex direction="row" justify="space-between" align="center" w="100%">
      <Box w="80px" />
      <Text fontSize="2xs" color="gray.400">
        Unstatiq does not own any of the data shown here. All rights reserved.
      </Text>
      <Flex direction="row" align="center" w="80px" justify="end" p={3}>
        <IconButton
          icon={<FaInfoCircle style={{ height: "20px", width: "20px" }} />}
          variant="ghost"
          transition="0.3s all"
          onClick={() => setShowInfoModal(true)}
          _hover={{
            bg: "none",
            color: "health.900"
          }}
          _active={{
            transform: "translateY(2px)"
          }}
          border="1px solid"
        />
        <IconButton
          icon={colorMode === 'light' ?
            <MdOutlineDarkMode style={{ height: "20px", width: "20px" }} /> :
            <MdDarkMode style={{ height: "20px", width: "20px" }} />
          }
          variant="ghost"
          transition="0.3s all"
          onClick={toggleColorMode}
          _hover={{
            bg: "none",
            color: "health.900"
          }}
          _active={{
            transform: "translateY(2px)"
          }}
        />
      </Flex>
    </Flex>
  )
}

export default Footer;
