import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
// import { GET_PRODUCT, GET_PRODUCTS } from '../../graphql/queries'
import {
  Box,
  Card,
  CardBody,
  CardHeader,
  Flex,
  Text,
  Stat,
  StatNumber,
  StatHelpText,
  Tag,
  Grid,
  GridItem,
  Stack,
  Image,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel
} from '@chakra-ui/react'
import DataTable from '../Common/DataTable'
import ItemInfoCard from './ItemInfoCard'
import ItemClassificationCard from './ItemClassificationCard'
import ItemAuthorizationCard from './ItemAuthorizationCard'
import ItemSupplierCard from './ItemSupplierCard'

import Logo from '../../assets/logo.png'

const mockItem = {
  "item_code": "HC001",
  "item_name": "Blood Pressure Monitor",
  "description": "Digital blood pressure monitor for home use",
  "supplier": {
    "name": "MedTech Solutions",
    "address": {
      "address": "2944 Clay St",
      "city": "San Francisco",
      "state": "CA",
      "zip_code": "94115"
    }
  },
  "category": "Medical Equipment",
  "unit_of_measure": "Each",
  "price": "49.99",
  "expiry_date": "2024-12-31",
  "pmas": {
    "pma_number": "1234567",
    "applicant": "MEDTRONIC VASCULAR INC",
    "supplement_number": "S038",
    "generic_name": "STENT, CAROTID",
    "trade_name": "Protege GPS Self-Expanding Peripheral Stent System",
    "advisory_committee": "Cardiovascular",
    "date_received": "11/13/2023",
    "decision_date": "11/21/2023",
    "supplement_type": "30-Day Notice",
    "supplement_reason": "Process Change - Manufacturer/Sterilizer/Package/Supplier",
    "ao_statement": "APPROVAL FOR THE PROTEGE GPS AND PROTEGE RX CAROTID STENT SYSTEMS. THIS DEVICE IS INDICATED FOR USED IN CONJUNCTION WITH EV3 EMBOLIC PROTECTION DEVICES, ARE INDICATED FOR THE TREATMENT OF PATIENTS AT HIGH RISK FOR ADVERSE EVENTS FROM CAROTID ENDARTERECTOMY WHO REQUIRE PERCUTANEOUS CAROTID REVASCULARIZATION AND MEET THE FOLLOWING CRITERIA:1) PATIENTS WITH CAROTID ARTERY STENOSIS (>= 50% FOR SYMPTOMATIC PATIENTS BY ULTRASOUND OR ANGIOGRAPHY OR >= 80% FOR ASYMPTOMATIC PATIENTS BY ULTRASOUND OR ANGIOGRAPHY) OF THE COMMON OR INTERNAL CAROTID ARTERY, AND2) PATIENTS MUST HAVE A REFERENCE VESSEL DIAMETER WITHIN THE RANGE OF 4.5 MM AND 9.5 MM AT THE TARGET LESION.",
    "classification": {
      "product_code": "NIM",
      "device_name": "Protege GPS Self-Expanding Peripheral Stent System",
      "device_class": "Cardiovascular",
      "medical_specialty": "Cardiovascular",
      "medical_specialty_description": "",
      "regulation_number": "07M-0049",
      "life_sustain_support_flag": true,
      "implant_flag": true,
      "definition": ""
    }
  },
  "k_510s": null,
  "classifications": [
    {
      "product_code": "1234567",
      "device_name": "MediScanner 2000",
      "device_class": "Class III",
      "medical_specialty": "Cardiovascular 1",
      "medical_specialty_description": "Devices that pertain to monitoring the heart and circulatory system",
      "regulation_number": "3425907",
      "life_sustain_support_flag": "Y",
      "implant_flag": "N",
      "definition": "Class III definition classification",
      "checklist": [
        {
          "approval": "Y"
        }
      ]
    },
    {
      "product_code": "1234567",
      "device_name": "MediScanner 2000",
      "device_class": "Class III",
      "medical_specialty": "Cardiovascular 2",
      "medical_specialty_description": "Devices that pertain to monitoring the heart and circulatory system",
      "regulation_number": "3425907",
      "life_sustain_support_flag": false,
      "implant_flag": false,
      "definition": "Class III definition classification"
    },
    {
      "product_code": "1234567",
      "device_name": "MediScanner 2000",
      "device_class": "Class III",
      "medical_specialty": "Cardiovascular 3",
      "medical_specialty_description": "Devices that pertain to monitoring the heart and circulatory system",
      "regulation_number": "3425907",
      "life_sustain_support_flag": false,
      "implant_flag": false,
      "definition": "Class III definition classification"
    },
    {
      "product_code": "1234567",
      "device_name": "MediScanner 2000",
      "device_class": "Class III",
      "medical_specialty": "Cardiovascular 4",
      "medical_specialty_description": "Devices that pertain to monitoring the heart and circulatory system",
      "regulation_number": "3425907",
      "life_sustain_support_flag": false,
      "implant_flag": false,
      "definition": "Class III definition classification"
    }
  ],
  "similar_products": [
    {
      "__typename": "ProductType",
      "id": "48ep4e5",
      "name": "BrightView LED Scalpel",
      "similarity": "99",
      "productCode": "FDA33245",
      "category": "Surgical Equipment",
      "subCategory": "Surgical Tools",
      "supplier": "SurgicalInnovations",
      "useCase": "Precision Surgery",
      "description": "The BrightView LED Scalpel is a revolutionary surgical tool that combines precision cutting with integrated LED lighting. It enhances visibility during procedures, increasing surgical accuracy and patient safety. The ergonomic design and cutting-edge technology make it a preferred choice for surgeons."
    },
    {
      "__typename": "ProductType",
      "id": "w2dqvgp",
      "name": "RespiroVent",
      "similarity": "59",
      "productCode": "FDA44356",
      "category": "Medical Device",
      "subCategory": "Respiratory Care",
      "supplier": "BreathEasy Technologies",
      "useCase": "Ventilation Support",
      "description": "RespiroVent is an advanced mechanical ventilator providing reliable respiratory support for critically ill patients. It features customizable ventilation modes, user-friendly interfaces, and robust build quality. The device is essential in ICUs and emergency care units, especially for patients requiring long-term ventilation."
    },
  ]
}

function transformDataArray(inputArray) {
  // Ensure the input array is not empty
  if (inputArray.length === 0) {
    return { columns: [], data: [] };
  }

  // Extract columns from the first object
  const columns = Object.keys(inputArray[0]);

  // Extract data from each object
  const data = inputArray.map(item => Object.values(item));

  return { columns, data };
}

const formatCSVHeader = (header) => {
  return header.map(name => {
    if (name === "similarity") {
      return { name, type: "slider-progress"}
    } else {
      return { name, type: "string" }
    }
  });
}

const Item = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [itemData, setItemData] = useState(null);
  const [similarItemsColumns, setSimilarItemsColumns] = useState([]);
  const [similarItemsData, setSimilarItemsData] = useState([]);

  // const { loading, error, data } = useQuery(GET_PRODUCT, {
  //   variables: {
  //     id: id
  //   }
  // })
  // const { loading: fetchLoading, error: fetchError, data: fetchData } = useQuery(GET_PRODUCTS);



  // useEffect(() => {
  //   if (data && data.product && fetchData && fetchData.products) {
  //     const productData = {...data.product, "similarItems" : fetchData.products, "image": Logo}

  //     setItemData(productData)
      
      
  //     const { columns: similarItemColumns, data: similarItemData } = transformDataArray(productData.similarItems);
  //     setSimilarItemsColumns(formatCSVHeader(similarItemColumns));
  //     setSimilarItemsData(similarItemData);

  //   }
  // }, [loading, error, data, fetchLoading, fetchError, fetchData])

  return (
    <Box h="100%" px={"100px"}>
      {itemData && (
        <>
          <Tabs colorScheme="health" align="center" isLazy variant="enclosed" h="100%">
            <TabList>
              <Tab>Overview</Tab>
              <Tab>Similar Devices</Tab>
            </TabList>
            <TabPanels align="start">
              <TabPanel>
                <Grid
                  maxH='545px'
                  templateRows='repeat(2, 1fr)'
                  templateColumns='repeat(3, 1fr)'
                  gap={3}
                  overflow="auto"
                >
                  <GridItem rowSpan={1} colSpan={2}>
                    <ItemInfoCard itemData={itemData} mockItem={mockItem} />
                  </GridItem>
                  <GridItem rowSpan={2} colSpan={1}>
                    <ItemAuthorizationCard itemData={itemData} mockItem={mockItem} />
                  </GridItem>
                  <GridItem rowSpan={1} colSpan={1}>
                    <ItemSupplierCard itemData={itemData} mockItem={mockItem} />
                  </GridItem>
                  <GridItem rowSpan={1} colSpan={1}>
                    <ItemClassificationCard itemData={itemData} mockItem={mockItem} />
                  </GridItem>
                </Grid>
              </TabPanel>
              <TabPanel>
                <DataTable
                  my={3}
                  title={"Similar Items"}
                  description={`Here are the items most like ${itemData.name}`}
                  columns={similarItemsColumns}
                  excludeColumns={["__typename", "id"]}
                  data={similarItemsData}
                  onRowClick={(id) => navigate(`/item/${id}`)}
                />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </>
      )}
    </Box>
  )
}

export default Item;
