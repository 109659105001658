import { extendTheme } from "@chakra-ui/react";


const theme = extendTheme({
  fonts: {
    body: "Inter, sans-serif",
    heading: "Inter, sans-serif",
  },
  config: {
    initialColorMode: 'system',
    useSystemColorMode: true,
  },
  colors: {
    health: {
      50: "#FFD6D6",
      100: "#FFC5C5",
      200: "#FFB2B2",
      300: "#FFA6A6",
      400: "#FF8686",
      500: "#FF7F7F",
      600: "#FF7676",
      700: "#FF6666",
      800: "#FF5454",
      900: "#FF4545",
      950: "#FF3636"
    }
  },
});

export default theme;
