import {
  Avatar,
  Flex,
  Text,
  IconButton
} from '@chakra-ui/react'
import { AiOutlineMail } from 'react-icons/ai';
import { FaTwitter, FaLinkedin } from 'react-icons/fa' 


const PersonInfoAndContact = ({ imgSrc, blurb, linkedIn, twitter, email, ...props }) => {
  return (
    <Flex direction="row" gap={2} align="center" h="85px" {...props}>
      <Avatar
        src={imgSrc}
        size="xl"
      />
      <Flex h="100%" direction="column" justify="space-between">
        <Text fontSize="sm">
          {blurb}
        </Text>
        <Flex direction="row" gap={3}>
          <IconButton
            p={0}
            variant="ghost"
            size="2xs"
            icon={<FaLinkedin />}
            onClick={() => window.open(linkedIn)}
            transition="0.2s all"
            _hover={{
              color: "health.900"
            }}
            _active={{
              bg: "none",
              transform: "translateY(1px)"
            }}
          />
          <IconButton
            p={0}
            variant="ghost"
            size="2xs"
            icon={<FaTwitter />}
            onClick={() => window.open(twitter)}
            transition="0.2s all"
            _hover={{
              color: "health.900"
            }}
            _active={{
              bg: "none",
              transform: "translateY(1px)"
            }}
          />
          <IconButton
            p={0}
            variant="ghost"
            size="2xs"
            icon={<AiOutlineMail />}
            onClick={() => window.open(`mailto:${email}`, '_self')}
            transition="0.2s all"
            _hover={{
              color: "health.900"
            }}
            _active={{
              bg: "none",
              transform: "translateY(1px)"
            }}
          />
        </Flex>
      </Flex>
    </Flex>
  )
}

export default PersonInfoAndContact;
