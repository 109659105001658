import { useState } from 'react'
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Flex,
  Text,
  Stat,
  StatNumber,
  StatLabel,
  Tag,
  IconButton
} from '@chakra-ui/react'
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import Classification from './Classification';

const ItemClassificationCard = ({ itemData, mockItem }) => {
  const [cardIndex, setCardIndex] = useState(1);

  const handleChangeIndex = (i) => {
    const newIndex = cardIndex + i;
    if (newIndex < 1 || newIndex > mockItem.classifications.length) {
      return null;
    } else {
      setCardIndex(newIndex);
    }
  }


  return (
    <Card
      h="100%"
      variant="outline"
      overflow="hidden"
    >
      <CardHeader p={2} borderBottom="1px solid" borderBottomColor="gray.300">
        <Flex direction="column">
          <Text fontSize="xs" color="gray.500">CLASSIFICATIONS</Text>
          <Flex direction="row" gap={2}>
            {mockItem.classifications.map((classification, index) => (
              <Tag size="md">
                {classification.device_class}
              </Tag>
            ))}
          </Flex>
        </Flex>
      </CardHeader>
      <CardBody p={2} overflowY="auto">
        <Classification classification={mockItem.classifications[cardIndex-1]} />
      </CardBody>
      <CardFooter p={2}>
        <Flex direction="row" align="center" justify="end" w="100%">
          <IconButton
            isDisabled={cardIndex === 1}
            icon={<FaChevronLeft />}
            variant="ghost"
            size="xs"
            onClick={() => handleChangeIndex(-1)}
            color="health.500"
          />
          <Text fontSize="sm">{cardIndex}</Text>
          <IconButton
            isDisabled={cardIndex === mockItem.classifications.length}
            icon={<FaChevronRight />}
            variant="ghost"
            size="xs"
            onClick={() => handleChangeIndex(1)}
            color="health.500"
          />
        </Flex>
      </CardFooter>
    </Card>
  )
}

export default ItemClassificationCard;
