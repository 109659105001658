import React, { useState, useEffect, useRef } from 'react'
import {
  Box,
  Flex,
  Icon,
  Image,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Td,
  Th,
  Progress,
  Tag,
  CircularProgress,
  CircularProgressLabel,
  Stat,
  StatNumber,
  useColorModeValue,
} from '@chakra-ui/react'

import { FaArrowUp, FaArrowDown } from 'react-icons/fa'
import LoadingSpinner from './LoadingSpinner'
import Logo from '../../assets/logo.png'

/**
 * USE THIS WHEN YOU NEED TO RENDER ACTUAL DATA LIKE A CSV
 */
// column types: string, number, slider-progress, circular-progress, button, tag, icon
const DataTable = ({ title, description, columns, excludeColumns, data, onRowClick, isLoading, ...props }) => {
  const headerRef = useRef(null);
  
  const renderCell = (column, cellData) => {
    switch (column.type) {
      case 'string':
        return (
          <Text maxH="85px" overflow="scroll" whiteSpace="wrap" textOverflow="ellipsis">{cellData}</Text>
        );
      case 'number':
        return (
          <Stat>
            <StatNumber fontSize="sm">{cellData}</StatNumber>
          </Stat>
        );
      case 'slider-progress':
        return (
          <Flex direction="row" align="center" gap="2">
            <Progress
              value={cellData}
              w="100%"
              borderRadius="md"
              colorScheme="health"
              size="sm"
            />
            <Text fontWeight="bold">{cellData}%</Text>
          </Flex>
        );
      case 'circular-progress':
        return (
          <Box align="center">
            <CircularProgress value={cellData} color="health.900">
              <CircularProgressLabel>{cellData}%</CircularProgressLabel>
            </CircularProgress>
          </Box>
        )
      case 'image':
        return (
          <Image src={Logo} objectFit="cover" boxSize="80px" borderRadius="full" />
        )
      case 'button':
        // Implement button rendering
        return null;
      case 'tag':
        return <Tag>{cellData}</Tag>;
      case 'icon':
        // Implement icon rendering
        return null;
      default:
        return <Text>{cellData}</Text>; // Default to Text if type is not recognized
    }
  };

  const [sortingAnchor, setSortingAnchor] = useState('');
  const [sortingDirection, setSortingDirection] = useState('');
  const [columnsToRender, setColumnsToRender] = useState([]);
  const [sortedData, setSortedData] = useState([]);

  const bg = useColorModeValue("white", "gray.800");
  const hoverBg = useColorModeValue("gray.100", "gray.700")

  const [headerHeight, setHeaderHeight] = useState(null);

  useEffect(() => {
    // Filter out excluded columns
    if (excludeColumns && excludeColumns.length > 0) {
      const filteredColumns = columns.filter(column => !excludeColumns.includes(column.name));

      // Map data to match the order of visible columns
      const filteredData = data.map(row =>
        filteredColumns.map(column => row[columns.indexOf(column)])
      );

      setColumnsToRender(filteredColumns);
      setSortedData(filteredData);
    } else {
      setColumnsToRender(columns);
      setSortedData(data);
    }
  }, [columns, excludeColumns, data]);

  useEffect(() => {
    if (headerRef.current) {
      const newHeight = headerRef.current.clientHeight;
      if (newHeight !== headerHeight) {
        setHeaderHeight(newHeight);
      }
    }
  }, [title, description, headerHeight])


  const handleSort = (column) => {
    if (column.type === 'button' || column.type === 'icon') {
      // Skip sorting for non-sortable columns
      return;
    }

    const newDirection = sortingDirection === 'asc' ? 'desc' : 'asc';
  
    if (sortingAnchor === column.name) {
      // If clicking on the same column, toggle the sort direction
      setSortingDirection(newDirection);
    } else {
      // If clicking on a different column, set new anchor and default direction
      setSortingAnchor(column.name);
      setSortingDirection('asc');
    }
  
    // Trigger sorting function
    setSortedData(sortData(column.name, newDirection, column.type));
  };

  const sortData = (anchor, direction) => {
    // Find the index of the sorting column
    const columnIndex = columns.findIndex((column) => column.name === anchor);
  
    if (columnIndex !== -1) {
      const compareFunction = (a, b) => {
        const aValue = a[columnIndex];
        const bValue = b[columnIndex];
  
        // Use typeof to determine the column type
        if (typeof aValue === 'string' && typeof bValue === 'string') {
          return direction === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
        } else if (typeof aValue === 'number' && typeof bValue === 'number') {
          return direction === 'asc' ? aValue - bValue : bValue - aValue;
        } else {
          // Default to string comparison if the column type is not recognized or mixed
          return direction === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
        }
      };
  
      // Clone the data array to avoid modifying the original array directly
      const newSortedData = [...sortedData];
      newSortedData.sort(compareFunction);

      return newSortedData;
    }
  };


  return (
    <Box
      borderRadius="md"
      border="1px solid"
      borderColor="gray.100"
      boxShadow="sm"
      pb={1}
      minW="400px"
      maxW="1200px"
      minH="200px"
      maxH="500px"
      overflow="auto"
      {...props}
    >
      {/* TITLE AND HEADER */}
      <Flex
        ref={headerRef}
        direction="row"
        p={3}
        bg={bg}
        justify="space-between"
        borderTopRadius="inherit"
        borderBottom="1px solid"
        borderColor="health.300"
        sx={{ position: 'sticky', top: '0', }}
        zIndex={10}
      >
        <Flex
          direction="column"
          justify="center"
        >
          <Text fontSize="lg" fontWeight="medium" color="health.500">{title}</Text>
          <Text fontSize="sm" fontWeight="light">{description}</Text>
        </Flex>
      </Flex>

      {/* TABLE */}
      {isLoading ? (
        <Box align="center" justify="center" py={10}>
          <LoadingSpinner h="40px" w="40px" color="health.500" />
        </Box>
      ) : (
        <Table variant="simple" size="sm" sx={{ position: "relative" }}>
          <Thead position="sticky" top={`${headerHeight}px`} bg={bg} zIndex={5}>
            <Tr>
              {columnsToRender.map((column, index) => (
                <Th
                  key={index}
                  sx={{ userSelect: 'none' }}
                >
                  <Flex align="center" gap={1} cursor="pointer" onClick={() => handleSort(column)} py={1}>
                    <Text>{column.name}</Text>
                    {sortingAnchor === column.name && (
                      <>
                        {sortingDirection === 'asc' ? (
                          <Icon as={FaArrowDown} h="16px" w="16px" />
                        ) : (
                          <Icon as={FaArrowUp} h="16px" w="16px" />
                        )}
                      </>
                    )}
                  </Flex>
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {data && sortedData && sortedData.map((row, rowIndex) => (
              <Tr
                key={rowIndex}
                cursor="pointer"
                onClick={() => onRowClick(data[rowIndex][1])}
                _hover={{
                  boxShadow: "sm",
                  bg: hoverBg
                }}
              >
                {row.map((cellData, columnIndex) => (
                  <Td key={columnIndex}>
                    {renderCell(columnsToRender[columnIndex], cellData)}
                  </Td>
                ))}
              </Tr>
            ))}
          </Tbody>
        </Table>
      )}
    </Box>
  )
}

export default DataTable;
