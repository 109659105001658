import {
  Input
} from '@chakra-ui/react'

const StaticInput = (props) => {
  return (
    <Input
      w="100%"
      fontSize="sm"
      value={props.value || ''}
      placeholder={props.placeholder || ''}
      borderRadius="md"
      transition="0.2s all"
      _hover={{
        borderColor: "health.900"
      }}
      _focus={{
        boxShadow: "none",
        outline: "none",
        borderColor: "health.900"
      }}
      {...props}
    />
  )
}

export default StaticInput;
