import {
  Icon
} from '@chakra-ui/react'

const Logo = ({activeProps, hoverProps, ...props}) => {
  return (
    <Icon viewBox="0 0 60 60" fill="none" strokeWidth="5" stroke="#FF7778" {...props} _hover={hoverProps} _active={activeProps}>
      <path d="M57.5 30 C57.5 45.18 45.18 57.5 30 57.5 14.81 57.5 2.5 45.18 2.5 30 2.5 14.81 14.81 2.5 30 2.5 45.18 2.5 57.5 14.81 57.5 30 z"></path>
      <path d="M37 10 C37 13.86 33.86 17 30 17 26.13 17 23 13.86 23 10 23 6.13 26.13 3 30 3 33.86 3 37 6.13 37 10 z"></path>
      <path d="M17 30 C17 33.86 13.86 37 10 37 6.13 37 3 33.86 3 30 3 26.13 6.13 23 10 23 13.86 23 17 26.13 17 30 z"></path>
      <path d="M37 50 C37 53.86 33.86 57 30 57 26.13 57 23 53.86 23 50 23 46.13 26.13 43 30 43 33.86 43 37 46.13 37 50 z"></path>
      <path d="M57 30 C57 33.86 53.86 37 50 37 46.13 37 43 33.86 43 30 43 26.13 46.13 23 50 23 53.86 23 57 26.13 57 30 z"></path>
      <path d="M25 15 C21.66 18.33 18.33 21.66 15 25 "></path>
      <path d="M15 35 C18.33 38.33 21.66 41.66 25 45 "></path>
      <path d="M35 45 C38.33 41.66 41.66 38.33 45 35 "></path>
    </Icon>
  )
}

export default Logo;
