import Logo from '../../assets/logo'

const LoadingSpinner = (props) => {
  return (
    <Logo
      className="rotate"
      {...props}
    />
  )
}

export default LoadingSpinner;
