import { gql } from '@apollo/client'

export const SEARCH = gql`
  query Search($query: String!) {
    search(query: $query) {
      id
      created
      modified
      brandName
      guid
      versionOrModelNumber
      deviceDescription
      companyName
      commercialDistributionStatus
      isPmExempt
      publishDate
      customerEmail
      customerPhone
      dunsNumber
      deviceInfo {
        id
        created
        modified
        category
        subCategory
        useCase
        description
      }
      supplier {
        id
        created
        modified
        registrationNumber
        name
        addresses {
          id
          created
          modified
          address
          city
          state
          zipCode
          countryCode
        }
      }
      gmdnTerms {
        id
        created
        modified
        code
        name
        definition
      }
      k510s {
        id
        created
        modified
        kNumber
        applicant
        deviceName
        advisoryCommittee
        advisoryCommitteeDescription
        decisionDate decisionCode
        decisionDescription
        clearanceType
        classification {
          id
          created
          modified
          productCode
          deviceName
          deviceClass
          medicalSpecialty
          medicalSpecialtyDescription
          regulationNumber
          lifeSustainSupportFlag
          implantFlag
          definition
        }
      }
      pmas {
        id
        created
        modified
        pmaNumber
        applicant
        genericName
        tradeName
        advisoryCommittee
        advisoryCommitteeDescription
        decisionCode
        aoStatement
        classification {
          id
          created
          modified
          productCode
          deviceName
          deviceClass
          medicalSpecialty
          medicalSpecialtyDescription
          regulationNumber
          lifeSustainSupportFlag
          implantFlag
          definition
        }
      }
    }
  }
`;

// export const GET_PRODUCT = gql`
//   query GetProduct($id: String!) {
//     product(id: $id) {
//       id
//       name
//       productCode
//       category
//       subCategory
//       supplier
//       useCase
//       description
//     }
//   }
// `;

// export const GET_PRODUCTS = gql`
//   query GetProducts {
//     products {
//       id
//       name
//       productCode
//       category
//       subCategory
//       supplier
//       useCase
//       description
//     }
//   }
// `;
