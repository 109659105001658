import React, { useState } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate
} from 'react-router-dom';
import { Flex, Box, IconButton, useColorMode } from '@chakra-ui/react';
import Logo from './assets/logo'

// Global


// Components
import Footer from './components/Common/Footer'
import ItemTable from './components/ItemTable'
import Item from './components/Item/Item'
import InfoModal from './components/Common/InfoModal';


function App() {
  const [showInfoModal, setShowInfoModal] = useState(false);

  return (
    <Box h="calc(100vh)" boxSizing="border-box">
      <Box h="125px" w="100%" align="center" py={5}>
        <Box cursor="pointer" display="inline-flex" onClick={() => window.open("https://www.unstatiq.com")}>
          <Logo
            h="80px"
            w="80px"
            transition="transform 0.3s ease"
            hoverProps={{
              transform: "rotate(90deg)",
            }}
            activeProps={{
              bg: "none",
              transform: "rotate(90deg) translateX(2px)"
            }}
          />
        </Box>
      </Box>
      <Box h="calc(100vh - 170px)" overflow="auto">
        <Routes>
          <Route exact path="/" element={<ItemTable />} />
          <Route exact path="/item/:id" element={<Item />} />
          <Route path="*" element={<Navigate to='/' replace />} />
        </Routes>
      </Box>
      <Box h="45px" w="100%" px={3} display="flex" alignItems="end">
        <Footer
          setShowInfoModal={setShowInfoModal}
        />
      </Box>
      {showInfoModal && (
        <InfoModal
          isOpen={showInfoModal}
          onClose={() => setShowInfoModal(false)}
        />
      )}
    </Box>
  );
}

export default App;
